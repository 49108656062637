import React, { useState } from "react";
import "../Joinus/Joinus.css";
import image5 from "../../Assets/Images/chart.png";
import image6 from "../../Assets/Images/foot.jpg";
import { useEffect } from "react";
import axios from "axios";
import Joinusloader from "./Joinusloader";

const Joinus = () => {
  const [joindata, setJoindata] = useState("");
  const [loading, setLoading] = useState(false);
  // console.log("The join", joindata);
  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}get-join-us`,
      method: "Get",
    })
      .then((res) => {
        let response = res.data.data.joinus;
        setJoindata(response);
        // setImageURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("The error is", err);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <section className="main-section secondbanner-background">
        <div class="container">
          <section className="">
            <div className="sec-head">
              {/* <h4 className="section-heading"><b>Join us</b>
            </h4> */}
            </div>
            <div className="para-sec-text">
              {loading ? (
                <>
                  <Joinusloader />{" "}
                </>
              ) : (
                <>
                  <h5>{joindata?.description}</h5>
                </>
              )}
            </div>
            <div className="join-us-btn all-btn-hover-eff">
              <button type="button" class="btn">
                Connect with me <i class="fa-solid fa-chevron-right"></i>
              </button>
            </div>
          </section>
        </div>
        <div className="water-markimage">
          <img src={image5} alt="" />
        </div>
        <div className=" watermark-second">
          <img src={image6} alt="" />
        </div>
      </section>
    </>
  );
};

export default Joinus;
