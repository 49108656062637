import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Glossaryloader = () => {
  return (
  <>

            <div className="container">
                <div className="row">
                <div className="col-md-4">
                <div className="">
  <Stack spacing={1}>
    <Skeleton className="" variant="text" width="100px" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
  </Stack>
</div>

</div>
<div className="col-md-4">
<div className="">
  <Stack spacing={1}>
    <Skeleton className="" variant="text" width="100px" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
  </Stack>
</div>
</div>
<div className="col-md-4">
<Stack spacing={1}>

    <Skeleton className="" variant="text" width="100px" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
</Stack>
</div>

                </div>
            </div>
  </>
  )
}

export default Glossaryloader
