import React from "react";
import { useContext } from "react";
import "./Secondbanner.css";
import image3 from "../../../Assets/Images/image2.jpg";
import image5 from "../../../Assets/Images/chart.png";
import image6 from "../../../Assets/Images/foot.jpg";
import InfoCard from "../../Cards/InfoCard/InfoCard";
import { ArticleContext } from "../../../Context/ArticleContext";
import Secondbannerloader from "./Secondbannerloader";
import { CategoryContext } from "../../../Context/CategoryContext";
import { Link } from "react-router-dom";

const Secondbanner = () => {
  // const {article,setArticle,loading,setLoading,imageUrl} = useContext(ArticleContext)
  const { category, setCategory, loading, imageurl, setImageURL } =
    useContext(CategoryContext);

  return (
    <>
      <div className="secondbanner-background">
        {/* <div className="second-ban-gradient"> */}
        <section className="main-section">
          <div className="banner-heading">
            <h5 className="text-center section-heading mb-4">Know your body</h5>
          </div>

          {/* {
          loading ? <> <Secondbannerloader/> </> : <>
            <div className="wrapper">
        {loading ? <p>Loading</p> : article?.data?.map((get , keys) => {
          return(
            <InfoCard
            cardName={get.title}
            cardDescription={get.description}
            cardImage={`${imageUrl}/${get.image}`}
            btnText="Read more"
            slug={get?.slug}
          />
          )
        }) } 
        </div>
          </>
        } */}
          <div className="container">
            <div className="row">
              {category?.map((elem, key) => {
                return (
                  <>
                    <div className="col-md-3">
                      <div className="second-banner-image">
                        <img src={imageurl + "/" + elem?.image} alt="" />
                      </div>
                      <div className="second-ban-des">
                        <h4 className="mt-3">{elem?.category_title}</h4>
                        {/* <p>{ elem.para}</p> */}
                        <Link
                          to={`/category/${elem?.category_slug}`}
                          state={{
                            title: elem?.category_title,
                            description: elem?.description,
                          }}
                        >
                          <p className="">
                            Learn more
                            <i class="fa-solid fa-greater-than ms-1"></i>
                            <i class="fa-solid fa-greater-than "></i>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section>

        {/* <div className="water-markimage">
          <img src={image5} alt="" />
        </div>
        <div className=" watermark-second">
          <img src={image6} alt="" />
        </div> */}
      </div>
      {/* </div> */}
    </>
  );
};

export default Secondbanner;
