import React from "react";
import "./Askourexpert.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import Askyourexpertloader from "./Askyourexpertloader";

const Askourexpert = () => {
  const [question, setQuestion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [refetch, setRefetch] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}get-qna`,
    })
      .then((res) => {
        let response = res?.data?.data?.qnas?.data;
        console.log("response ", response);
        setLoading(false);
        setQuestion(response);
      })
      .catch((err) => {
        console.log("The question error is", err);
        setLoading(false);
      });
  }, [refetch]);

  const [searchData, setSearchData] = useState([]);

  const searchHandler = (e) => {
    e.preventDefault();
    axios({
      url: `${process.env.REACT_APP_API_URL}search-qna?search_key=${searchValue}`,
      method: "GET",
    })
      .then((res) => {
        let response = res?.data?.data?.qnas?.data;
        console.log(response);
        setSearchData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    setQuestion([...searchData]);
  }, [searchData]);

  const storeQuestionHandler = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}store-question`,
      data: {
        question: searchValue,
      },
    })
      .then((res) => {
        setLoading(false);
        Swal.fire({
          title: "Thank You!",
          text: "Your request for store question has been submitted successfully",
          icon: "success",
          confirmButtonText: "OK",
          timerProgressBar: true,
          timer: 5000,
        });
        setSearchValue("");
        setRefetch(!refetch)
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: "OOps !",
          text: "An error occurred !",
          icon: "error",
          confirmButtonText: "OK",
          timerProgressBar: true,
          timer: 5000,
        });
        setRefetch(!refetch)
      });
  };

  return (
    <>
      <section className="main-section">
        <div className="container">
          <h1 className="text-center  mb-5">Ask Our Expert</h1>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="mcq-card">
                <ul className="qna-ul">
                  <li className="qna-li">
                    {" "}
                    <input
                      className="qna-input"
                      type="search"
                      placeholder="Enter here. . ."
                      aria-label="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </li>
                  <li className="qna-li">
                    <div className="button-slider">
                      <button type="submit" onClick={(e) => searchHandler(e)}>
                        Search
                      </button>
                    </div>
                  </li>
                </ul>
                {loading ? (
                  <Askyourexpertloader/>
                ) : question?.length <= 0 ? (
                  <div className="no-search">
                    <p>No Questions Found</p>
                    <div className="button-slider">
                      <button onClick={(e) => storeQuestionHandler()}>
                        Do you want to store your entered question
                      </button>
                    </div>
                  </div>
                ) : (
                  question?.map((get, keys) => {
                    return (
                      <div className="mt-4">
                        <div
                          class="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                              >
                                {get?.question}
                              </button>
                            </h2>
                            <div
                              id="flush-collapseOne"
                              class="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div class="accordion-body">
                                {parse(get?.answer)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Askourexpert;
