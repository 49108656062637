import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Blogloader = () => {
  return (
    <>
  <div className="container">
    <div className="row">
    <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={350} height={200} />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        
                    </Stack>
                </div>
    <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={350} height={200} />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        
                    </Stack>
                </div>
    <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={350} height={200} />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        
                    </Stack>
                </div>
    </div>
  </div>
    </>
  )
}

export default Blogloader