import axios from "axios";
import React, {useEffect , useState ,useContext} from "react";
import InfoCard from "../Components/Cards/InfoCard/InfoCard";
import parse from "html-react-parser";
import { ArticleContext } from "../Context/ArticleContext";
import Cardloader from "../Components/Cards/InfoCard/Cardloader";

export const TopicList = () => {

  const {article,setArticle,loading,setLoading,imageUrl} = useContext(ArticleContext); 

  return (
    <>
     {
      loading ? <><Cardloader/> </> : <>
       <section className="main-section">
        <div className="container">
          <div className="row">
            <div>
              <h4 className="text-center  section-heading" id="article">Read Our Article</h4>
            </div>
          </div>
        </div>

        <div className="wrapper">
        {loading ? <p>Loading</p> : article?.data?.map((get , keys) => {
          return(
            <InfoCard
            cardName={get.title}
            cardDescription={get.description}
            cardImage={`${imageUrl}/${get.image}`}
            btnText="Read more"
            slug={get?.slug}
          />
          )
        }) } 
          {/* <InfoCard
            cardName="Topic Name"
            cardDescription="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. "
            cardImage="https://picsum.photos/200/300"
            btnText="Read more"
          />
              </>
            )
          })
         }
          {/* <InfoCard
            cardName="Topic Name"
            cardDescription="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. "
            cardImage="https://picsum.photos/200/300"
            btnText="Read more"
          /> */}
          {/* <InfoCard
            cardName="Topic Name"
            cardDescription="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. "
            cardImage="https://picsum.photos/200/300"
            btnText="Read more"
          /> */}
        </div>
      </section>
       </>
     }
    </>
  );
}
