import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Contactusloader = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          {/* <div className="col-md-4">

</div> */}
          <div className="col-md-4">
            <div className="">
              <Stack spacing={1}>
                <Skeleton
                  className=""
                  variant="rectangle"
                  width="100%"
                  height={200}
                />
              </Stack>
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <Stack spacing={1}>
                <Skeleton
                  className=""
                  variant="rectangle"
                  width="100%"
                  height={200}
                />
              </Stack>
            </div>
          </div>
          <div className="col-md-4">
            <Stack spacing={1}>
              <Skeleton
                className=""
                variant="rectangle"
                width="100%"
                height={200}
              />
            </Stack>
          </div>

          {/* <div className="col-md-4">
<Stack spacing={1}>

    <Skeleton className="" variant="text" width="100px" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
</Stack>
</div> */}
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <Stack spacing={1}>
              <Skeleton className="" variant="text" width="50%" height={20} />
              <Skeleton
                className=""
                variant="rectangle"
                width="70%"
                height={200}
              />
            </Stack>
          </div>
          <div className="col-md-6">
            <Stack spacing={1}>
              <Skeleton
                className="mt-2"
                variant="rectangle"
                width="70%"
                height={20}
              />
              {/* <Skeleton className="" variant="text" width="80%" height={20} /> */}
              <Skeleton
                className="mt-5"
                variant="rectangle"
                width="70%"
                height={20}
              />
              {/* <Skeleton className="" variant="text" width="80%" height={20} /> */}
              <Skeleton
                className="mt-5"
                variant="rectangle"
                width="70%"
                height={40}
              />
              <Skeleton className="" variant="text" width="70%" height={20} />
              {/* <Skeleton
                className=""
                variant="rectangle"
                width="70%"
                height={30}
              /> */}
              {/* <Skeleton className="" variant="text" width="80%" height={20} /> */}
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactusloader;
