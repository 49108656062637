import React, { Component } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import "./Firstbanner.css";
import image3 from "../../../Assets/Images/presentation.jpg";
import parse from "html-react-parser";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Firstbannerloader from "./Firstbannerloader";

const Firstbanner = () => {
  const [category, setCategory] = useState([]);
  const [imageURL, setImageURL] = useState("");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}get-carousel`,
      method: "Get",
    })
      .then((res) => {
        let response = res.data.data.carousels.data;
        setCategory(response);
        setImageURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("The error is", err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="section-background">
      <div className="container-fluid">
        <div className="row first-banner-row">
          {/* <OwlCarousel className='owl-theme' loop margin={10} item={1}

            nav> */}
          <div className="col-md-1 first-banner-social-media">
            <div className="social-media-section">
              <div className="first-banner-icon first-facebook">
                <a href="https://www.facebook.com/" target="_blank">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
              </div>

              <div className="first-banner-icon first-twitter">
                <a href="https://twitter.com/" target="_blank">
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </div>

              <div className="first-banner-icon first-instagram">
                <a href="https://www.instagram.com/" target="_blank">
                  <i class="fa-brands fa-square-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-11">
            {loading ? (
              <>
                <Firstbannerloader />
              </>
            ) : (
              <>
                <OwlCarousel className="owl-theme" loop items={1} dots={false}>
                  {category?.map((event) => {
                    return (
                      <>
                        <div className="item">
                          <div className="row">
                            <div className="col-md-6 ">
                              <div className="">
                                <div className="">
                                  <h1 className="first-banner-heading">
                                    {event?.title}
                                  </h1>
                                </div>
                                <div>
                                  <p className="first-banner-para">
                                    {parse(event?.description || "")}
                                  </p>
                                </div>

                                <div className="first-banner-btn all-btn-hover-eff">
                                  <button type="button" class="btn ">
                                    <a href={event?.url} target="_blank">
                                      Read More
                                    </a>
                                    <i class="fa-solid fa-chevron-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="first-banner-image">
                                <img
                                  src={imageURL + "/" + event?.image}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </OwlCarousel>
              </>
            )}
          </div>
          {/* <OwlCarousel className='owl-theme' loop margin={10} item={1}
          
          nav> */}

          {/* </OwlCarousel>; */}
        </div>
      </div>
    </div>
  );
};

export default Firstbanner;
