import React, { useContext } from "react";
import "./Thirdbanner.css";
import image4 from "../../../Assets/Images/navbar-img.png";
import { AboutContext } from "../../../Context/AboutContext";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Thirdbannerloader from "./Thirdbannerloader";

const Thirdbanner = () => {
  const {
    about,
    setAbout,
    loading,
    setLoading,
    fileImageUrl,
    setFileImageUrl,
  } = useContext(AboutContext);

  console.log("about : ", about);
  return (
    <>
      <section className="main-section">
        <div className=""></div>
        <div className="third-ban-background" style={{ backgroundImage:`url(${fileImageUrl}/${about.image})`,backgroundPosition:"center center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
          <div className="container-fluid third-banner-gradient">
            <div className="row">
             
                  <div className="col-md-6 third-ban-section ">
                   {
                    loading ? <><Thirdbannerloader/></>:<>
                     <div className="third-sec-head">
                      <h5 className="section-heading">{about?.summary}</h5>
                      <p>
                        {parse(about?.description?.substring(0, 500) || "")}
                      </p>
                      <div className="about-sec-btn">
                        <Link to={"/aboutpage"}>
                          <p className="">
                            Learn more
                            <i class="fa-solid fa-greater-than ms-1"></i>
                            <i class="fa-solid fa-greater-than "></i>
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="third-ban-img">
                      {/* <img src={fileImageUrl + "/" + about?.image }  alt="aboutImage" /> */}
                      <img src={image4} alt="" />
                    </div>
                    </>
                   }
                  </div>
               
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Thirdbanner;
