import React from 'react'
import Image1 from '../../Assets/Images/first.png'
import Image2 from '../../Assets/Images/second.png'
import Image3 from '../../Assets/Images/third.png'

const Topnavbar = () => {
  return (
   <>
   <div className="  pb-3
    ">
    {/* <div className="row ">
        <div className="col-md-3 ">
            <div className='top-nav-img'>
                <img src={Image1} alt="" />
            </div>
        </div>
        <div className="col-md-3 ">
            <div className='top-nav-img'>
                <img src={Image1} alt="" />
            </div>
        </div>
        <div className="col-md-3 ">
            <div className='top-nav-img'>
                <img src={Image1} alt="" />
            </div>
        </div>
        <div className="col-md-3 ">
            <div className='top-nav-img'>
                <img src={Image1} alt="" />
            </div>
        </div>
    </div> */}
     <div className='top-bar-main-section bg-white'>
     <div className='top-nav-img'>
                <img src={Image1} alt="" />
            </div>
            <div className='top-nav-img'>
                <img src={Image3} alt="" />
            </div>
     <div className='top-nav-img'>
                <img src={Image2} alt="" />
            </div>
    
   

     </div>
   </div>
   </>
  )
}

export default Topnavbar
