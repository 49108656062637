import React from "react";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Categorydetailsloader = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="mt-5">
              <Stack spacing={1}>
                <Skeleton className="mt-5" variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton className="mt-5" variant="text" width="100%" height={75} />
              </Stack>
            </div>
          </div>
          <div className="col-md-6 p-5">
            <Stack spacing={1}>
              <Skeleton variant="rectangular" width="100%" height={200} />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categorydetailsloader;
