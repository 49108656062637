import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser'
import { NavLink } from 'react-router-dom';

export default function CardMarkup(props) {
  const { cardName, cardDescription, cardImage, btnText ,slug } = props;
  return (
    <div className='infoCard'>
      <div className="infoCard__body">
        <img src={cardImage} className="infoCard__image" alt='' />
        <h5 className='infoCard__name mb-3'><b>{cardName}</b></h5>
        <p className="infoCard__description">{parse(cardDescription.substring(0,100))}</p>

      </div>
      <NavLink to={`/categorydetails/${slug}`}>
      <button className="infoCard__btn">
        
        <div className='btn__text'>{btnText}</div>
   
        <div className='btn__icon'><FontAwesomeIcon icon={faChevronRight} /></div>
      </button>
      </NavLink>


    </div>

  )
}
