import axios from "axios";
import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import TableSkeleton from "../Helper/SkeletonLoader/TableSkeleton";

const Resource = () => {
  const [loading, setLoading] = useState(true);
  const [resource, setResource] = useState([]);

  useEffect(() => {
      setLoading(true);
      axios({
          method: 'GET',
          url:`${process.env.REACT_APP_API_URL}get-resource`
      }).then((res) => {
          setLoading(false);
          let response = res?.data?.data?.resources?.data;
          setResource(response)
      }).catch((err) => {
          console.log(err.message);
          setLoading(false);
      })
  },[])

  return (
    <>
      <h1 className="text-center mb-4 mt-4">Resources</h1>
      {/* <div className="container">
        <div className="row ">
          <table className="table table-bordered border-primary">
            <thead className="">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Words</th>
                <th scope="col">Meaning</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
              <TableSkeleton />
              ) : (
                resource.map((get, keys) => {
                  return (
                    <tr>
                      <th scope="row">{keys + 1}</th>
                      <td>{get?.title}</td>
                      <td>
                        <p>
                          {parse(get?.detail)}
                        </p>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div> */}
      <div className="container">
  <div className="card glossary-main-card">
    <div className="card-body">
    <div className="row glossary-word-color">
          <div className="col-md-4">
            <div>
              <h3> Resource with <br /> element</h3>
            </div>
          </div>
          {
              loading ? <> <p>Loading </p> </> : <>
          {
            resource?.map ((event)=>{
              return (
                <>
                  <div className="col-md-4">
            <div className="">
           
                <h6 className=""> {event?.title}</h6>
              <p>{parse(event?.detail) || ""}</p>
             
            </div>
          </div>
                </>
              )
            })
          }
           </>
            }
        
         
        </div>
       
           
    </div>
  </div>
      </div>
    </>
  );
};

export default Resource;
