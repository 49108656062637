import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CardMarkup from "../Cards/InfoCard/CardMarkup";
import "./Blog.css";
import Blogloader from "./Blogloader";
const Blog = () => {
  const [loading, setLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [searchdata,setSearchdata]=useState('');
  const params= useParams();

const handleChange = (e) => {
e.preventDefault();
axios({
  url: `${process.env.REACT_APP_API_URL}get-blog`,
  method: "GET",
  params:{
    searchKey:searchdata
  }
})
  .then((res) => {
    let response = res?.data?.data;
    setBlog(response?.blogs);
    setImageUrl(response?.image_base_url);
    setLoading(false);
  })
  .catch((err) => {
    console.log(err.message);
    setLoading(false);
  });
}

  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}get-blog`,
      method: "GET",
      
    })
      .then((res) => {
        let response = res?.data?.data;
        setBlog(response?.blogs);
        setImageUrl(response?.image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="container">
        <h1 className="text-center mb-5 mt-5">BLOG</h1>
        <nav className=" bg-light mt-4 mb-4">
        <div className="text-center">
          
          <form className="d-flex" role="search " onSubmit={handleChange}>
            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" value={searchdata} onChange={(e)=>setSearchdata(e.target.value)} />
            {/* <button className="btn btn-outline-success" type="submit" onClick={handleChange}>Search</button> */}
          </form>
        </div>
      </nav>
        <div className="row">
          {loading ? (
           <><Blogloader/> </>
          ) : (
            blog?.data?.map((get, keys) => {
              return (
                <div className="col-md-4">
                  <div>
                    <CardMarkup
                      cardName={get.title}
                      cardDescription={get.description.substring(0,50)}
                      cardImage={`${imageUrl}/${get.image}`}
                      btnText="Read More"
                      slug={get.slug}
                    />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
export default Blog;
