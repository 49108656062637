import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Askourexpert from "../Components/Askourexpert/Askourexpert";
import Blog from "../Components/Blog/Blog";
import Categorydetails from "../Components/Categorydetails/Categorydetails";
import Category from "../Components/Categorypage/Category";
import Glossary from "../Components/Glossary/Glossary";
import Images from "../Components/Images/Images";
import Mcq from "../Components/Mcq/Mcq";
import Aboutpage from "../Pages/Aboutpage";
import Contactuspage from "../Pages/Contactuspage";

import Homepage from "../Pages/Homepage";
import Mcqpage from "../Pages/Mcqpage";
import Resource from "../Pages/Resource";

const Homerouting = () => {
  return (
    <>
      <Routes>
        <Route path="/aboutpage" element={<Aboutpage />} exact />
        <Route path="/" element={<Homepage />} exact />
        <Route path="/article-mcq/:slug" element={<Mcq />} exact />
        <Route path="/glossary" element={<Glossary />} exact />
        <Route path="/blog" element={<Blog />} />
        <Route path="/askourexpert" element={<Askourexpert />} />
        <Route path="/categorydetails/gallery/:slug" element={<Images />} />
        <Route path="/category/:slug" element={<Category />} />
        {/* <Route path="/category" element={<Category />} /> */}
        <Route path="/categorydetails/:slug" element={<Categorydetails />} />
        <Route path="/contactuspage" element={<Contactuspage />} />
        <Route path="/resource" element={<Resource />} />
      </Routes>
    </>
  );
};

export default Homerouting;
