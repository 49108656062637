import React, {createContext, useEffect , useState} from 'react';
import axios from 'axios';

export const AboutContext = createContext();

export const AboutProvider = (props) => {

    const [about , setAbout] = useState({});
    const [loading , setLoading] = useState(false);
    const [fileImageUrl , setFileImageUrl] = useState("");
    
  
    useEffect(() => {
      setLoading(true);
      axios({
        url:`${process.env.REACT_APP_API_URL}get-about-us`,
        method: "GET",
      }).then((res) => {
        let response  = res?.data?.data;
        setAbout(response?.about);
        setFileImageUrl(response?.image_base_url);
        setLoading(false);
      }).catch((err) => {
        console.log(err.message)
        setLoading(false);
      })
    },[])
    console.log("The about is :",about)
    return(
        <AboutContext.Provider value={{about , setAbout,loading , setLoading,fileImageUrl , setFileImageUrl}}>
            {props.children}
        </AboutContext.Provider>
    )
}