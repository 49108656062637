import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Footerloader = () => {
  return (
  <>
   <div className="container">
                <div className="row">
                    {/* <div className="col-md-1">

                    </div> */}
       

<div className="">
  <Stack >
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    {/* <Skeleton className="" variant="text" width="100%" height={50} /> */}
    {/* <Skeleton variant="rectangular" width={100} height={50} /> */}

    {/* <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} /> */}
  </Stack>
</div>
{/* <div className="col-md-5">
<Stack spacing={1}>
<Skeleton variant="rectangular" width={350} height={200} />


    <Skeleton className="" variant="text" width="100px" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
    <Skeleton className="" variant="text" width="100%" height={50} />
</Stack>
</div> */}

                </div>
            </div>
  </>
  )
}

export default Footerloader