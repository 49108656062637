import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Joinusloader = () => {
  return (
    <div>
        <div className='col-md-12 p-5'>
                    <Stack spacing={1}>
                    
                        <Skeleton variant="text" width="100%" height={30} />
                        <Skeleton variant="text" width="100%" height={30} />
                       
                        
                    </Stack>
                </div>
    </div>
  )
}

export default Joinusloader
