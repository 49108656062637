import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import "./Contactus.css";
import Contactusloader from "./Contactusloader";
import Contact1 from '../../Assets/Images/navbar-img.png'

const Contactus = () => {
  const [contact, setContact] = useState();
  const [name,setName]=useState('')
  const [email,setEmail] = useState('')
  const [message,setMessage]=useState('')
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}get-setting`,
      method: "Get",
    })
      .then((res) => {
        let response = res.data.data.setting;
        // console.log(response);
        setContact(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log("THe contact error is", err);
        setLoading(false);
      });
  
  }, []);

  const sendMessage = (e) =>{
    e.preventDefault()
    axios({
      url: `${process.env.REACT_APP_API_URL}store-feedback`,
      method: "POST",
      data:{
        name:name,
        email:email,
        message:message

      }
    })
      .then((res) => {
        // let response = res.data.data.setting;
        // console.log(response);
        // setContact(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log("THe contact error is", err);
        setLoading(false);
      });
  }

  return (
    <>
      <div>
        <div className="container">
          <h1 className="text-center mt-4 mb-4">Contact Us</h1>
          {/* <div className="row">
          
           {
            loading ? <> <Contactusloader/></>:<>
             <div className='contact-image'>
              <img src={contact?.image} alt="hello" />
            </div>
            </>
           }
            <h6>
              Location : {contact?.address}
            </h6>
            <div>
              {contact?.google_location}
            </div>
        </div> */}

          {loading ? (
            <>
              <Contactusloader />{" "}
            </>
          ) : (
            <>
              <div className="row text-center">
                <div className="col-md-4">
                  <div className="bg-light p-3">
                    <div className="contact-us-icon mb-2">
                      <i class="fa-solid fa-location-dot"></i>
                    </div>
                    <h5>OUR OFFICE</h5>
                    <p>{contact?.address}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="bg-light p-3 ">
                    <div className="contact-us-icon mb-2">
                      <i class="fa-solid fa-phone"></i>
                    </div>
                    <h5>PHONE NUMBER</h5>
                    <p>{contact?.contact1}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="bg-light p-3">
                    <div className="contact-us-icon mb-2">
                      <i class="fa-solid fa-envelope"></i>
                    </div>
                    <h5>EMAIL</h5>
                    <p>{contact?.email1}</p>
                  </div>
                </div>
              </div>
              <div className="row  contact-us-background p-4 mt-5">
                <div className="col-md-6">
                  <div>
                    <h5 className="mt-5 mb-5">Contact Info</h5>
                    <div className="contact-image">
                      {/* <img src={contact?.image} alt="" /> */}
                      <img src={Contact1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-user-information mt-5">
                   <div>
                   <label htmlFor="">Name</label>
                   <div>
                   <input type="text" placeholder="Enter your valid name" value={name} onChange={(e)=>setName(e.target.value)} />
                   </div>
                   </div>
                   <div>
                   <label htmlFor="">Email</label>
                   <div>
                   <input type="email" placeholder="Enter your valid email name" value={email} onChange={(e)=>setEmail(e.target.value)} />
                   </div>
                   </div>
                   <div>
                    <label htmlFor="">Message</label>
                    <div>
                      <textarea name="" id="" cols="30" rows="3" placeholder="Enter your message" value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>
                    </div>
                   </div>
                   <div className="mt-3">
                    <button type="submit" onClick={sendMessage}> 
                      Submit
                    </button>
                   </div>


                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Contactus;
