import React from "react";
import { useEffect, useState, useContext } from "react";
import { CategoryContext } from "../../Context/CategoryContext";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import Topnavbar from "../Topnavbar/Topnavbar";

const Navbar = () => {
  const navigate = useNavigate();

  const { category, setCategory } = useContext(CategoryContext);

  const activeClass = { borderBottom: "1px solid white" };

  const changeSection = (e) => {
    e.preventDefault();
    navigate("/category");
  };

  return (
    <>
      <div>
        <div className="nav-bar-background">
          {/* <Topnavbar /> */}
          <nav className="navbar navbar-expand-lg me-auto">
            <div className="container-fluid">
              {/* <a className="navbar-brand" href="#">Navbar</a> */}
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i class="fa-solid fa-bars"></i>
              </button>
              <div
                className="collapse navbar-collapse m-auto pt-2"
                id="navbarNav"
              >
                <ul className="navbar-nav m-auto ">
                  <li className="nav-item  ">
                    <NavLink
                      className="nav-link lower-border"
                      to={"/"}
                      style={({ isActive }) =>
                        isActive ? activeClass : undefined
                      }
                      end
                    >
                      Home
                    </NavLink>
                  </li>

                  <li className="nav-item  ">
                    <NavLink
                      className="nav-link navbar-categories " //lower-border
                      // to={"/aboutpage"}
                      // style={({ isActive }) =>
                      //   isActive ? activeClass : undefined
                      // }
                    >
                      <div className="dropdown categories_drop_down_hide     ">
                        <button
                          className=" dropdown-toggle categories-button"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          // onClick={(e)=>changeSection(e)}
                        >
                          Categories
                        </button>
                        <ul className="dropdown-menu categories-list w-100">
                          {category?.map((item) => {
                            return (
                              <>
                                <li className="navbar-link ">
                                  <Link
                                    className="mt-3 text-capitalize"
                                    to={`/category/${item?.category_slug}`}
                                    state={{
                                      title: item?.category_title,
                                      description: item?.description,
                                    }}
                                  >
                                    {/* {item?.category_title?.substring(0,15)} */}
                                    {item?.category_title}
                                    {/* onClick={navigate(`categorydetails/${item.category_slug}`)} */}
                                  </Link>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        {/* <ul className="dropdown-menu categories-list w-100" >
                            <li className="navbar-link ">

                             <div>
                             hello world Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente, expedita.
                             </div>
                            </li>
                            <li className="navbar-link ">

                             <div>
                             hello world Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente, expedita.dfaslkkglsahghsadkjgajdkhglak
                             </div>
                            </li>


                        </ul> */}
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-item  ">
                    <NavLink
                      className="nav-link lower-border"
                      to={"/aboutpage"}
                      style={({ isActive }) =>
                        isActive ? activeClass : undefined
                      }
                    >
                      About us
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link lower-border"
                      to={"/glossary"}
                      style={({ isActive }) =>
                        isActive ? activeClass : undefined
                      }
                    >
                      Glossary
                    </NavLink>{" "}
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link lower-border"
                      to={"/blog"}
                      style={({ isActive }) =>
                        isActive ? activeClass : undefined
                      }
                    >
                      Blog
                    </NavLink>{" "}
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link lower-border"
                      to={"/askourexpert"}
                      style={({ isActive }) =>
                        isActive ? activeClass : undefined
                      }
                    >
                      Ask Our Expert{" "}
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link lower-border"
                      to={"/contactuspage"}
                      style={({ isActive }) =>
                        isActive ? activeClass : undefined
                      }
                    >
                      Contact us
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <a className="nav-link lower-border" href="#">
                      <span>
                        <i class="fa-solid fa-phone"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Navbar;
