import React from "react";
import "./Pagehead.css"

const Pagehead = (props)=>{
     const heading = props.heading;
    return(
        <>
        <div className="page_hed">
        <h1>{props.heading}</h1>
        </div>
        </>
    )
}

export default Pagehead;