import React, { useState, useEffect, useContext } from "react";
import "./Aboutus.css";
import axios from "axios";
import parse from 'html-react-parser'
// import group_img from "../About/group.jpg";

import Aboutloader from "./Aboutloader.jsx";
import { AboutContext } from "../../Context/AboutContext";

const Aboutus = () => {
  const {
    about,
    setAbout,
    loading,
    setLoading,
    fileImageUrl,
    setFileImageUrl,
  } = useContext(AboutContext);

  return (
    <>
      <div className="all-cont">
        <div className="container txt-cont">
          <div className="txt-hed">
            <h2> {about?.seo_title}</h2>
            {/* <h2>know your body</h2> */}
          </div>
          <div className="txt-sub-hed">
            {loading ? (
              <>
                {" "}
                <Aboutloader />
              </>
            ) : (
              <>
                <p>{parse(about?.summary || "")}</p>
              </>
            )}
          </div>
        </div>

        <div className="grp-img">
        {
          about?.image ?   <img src={`${fileImageUrl}/${about?.image}`} /> : null
        }
        </div>

        <div className="container sec-txt-cont">
          <p>
            
            {about?.description}
          </p>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
