import React, { useState, useEffect } from "react";
import "./Glossary.css";
import parse from "html-react-parser";
import axios from "axios";
import Glossaryloader from "./Glossaryloader";
const Glossary = () => {
  const [loading, setLoading] = useState(false);
  const [glossary, setGlassary] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}get-glossary`,
      method: "GET",
    })
      .then((res) => {
        let response = res?.data?.data?.glossaries?.data;
        setGlassary(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <h1 className="text-center mb-4 mt-4">Glossary</h1>

      <h4 className="text-center mb-4 mt-4">Words Meaning</h4>
      {/* <div className="container">
        <div className="row ">
          <table className="table table-bordered border-primary">
            <thead className="table-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Words</th>
                <th scope="col">Meaning</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <p>Loading</p>
              ) : (
                glossary.map((get, keys) => {
                  return (
                    <tr>
                      <th scope="row">{keys + 1}</th>
                      <td>{get?.title}</td>
                      <td>
                        <p>{parse(get?.detail)}</p>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="container">
        <div className="card glossary-main-card">
          <div className="card-body">
            <div className="row glossary-word-color">
              {/* <div className="col-md-4">
            <div>
              <h3>Words and <br />  meaning</h3>
            </div>
          </div> */}
              {loading ? (
                <>
                  <Glossaryloader />{" "}
                </>
              ) : (
                <>
                  {glossary?.map((event) => {
                    return (
                      <>
                        <div className="col-md-4">
                          <div className="">
                            <h6 className=""> {event?.title}</h6>
                            <p>{parse(event?.detail) || ""}</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
              {/* <div className="col-md-4">
            <div className="">
              <h6 className=""> 3th World</h6>
              <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo a, tempora consectetur repellendus impedit doloremque sint omnis eum! Temporibus, alias.</p>
            </div>
          </div> */}
              {/* <div className="col-md-4">
            <div className="">
              <h6>
                4th World
              </h6>
              <p >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam impedit facere expedita laboriosam hic officiis! Incidunt voluptatibus quod delectus suscipit!
              </p>
            </div>
          </div> */}
            </div>
            {/* <div className="row glossary-word-color">
          <div className="col-md-4">
            <div>
              <h1>Aa</h1>
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <h6 className=""> Accomodation</h6>
              <p> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo a, tempora consectetur repellendus impedit doloremque sint omnis eum! Temporibus, alias.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <h6>
              Ammonia
              </h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam impedit facere expedita laboriosam hic officiis! Incidunt voluptatibus quod delectus suscipit!
              </p>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Glossary;
