import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Image2 from "../../Assets/Images/navbar-img.png";
import { CategoryContext } from "../../Context/CategoryContext";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import Footerloader from "./Footerloader";
import Topnavbar from "../Topnavbar/Topnavbar";
import { AboutContext } from "../../Context/AboutContext";
const Footer = () => {
  const [setting, setSetting] = useState("");
  const { category, setCategory, loading } = useContext(CategoryContext);
  const { about } = useContext(AboutContext);
  const year = new Date().getFullYear();

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_API_URL}get-setting`,
      method: "Get",
    })
      .then((res) => {
        let response = res.data.data.setting;
        setSetting(response);
      })
      .catch((err) => {
        console.log("The error setting is ", err);
      });
  }, []);
  return (
    <>
      <div className="container footer-main">
        <div className=" row">
          <Topnavbar />
          <div className="col-md-3 col-xs-6 col-sm-6 col-6 col-12">
            <div className="footer-logo">
              <img src={Image2} alt="" />
            </div>
            <div className="txt-para-sec">
              <p>
                {about?.summary}
                {/* SimpleText is a text editor made for the classic Mac OS
                operating system. It replaced simpler text editor programs made
                in the age of the command-line interface. */}
              </p>
            </div>

            <div className="social-media-icon">
              <span className="first-facebook">
                <a href="https://www.facebook.com/" target="_blank">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
              </span>

              <span className="first-twitter">
                <a href="https://www.twitter.com/" target="_blank">
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </span>

              <span className="first-instagram">
                <a href="https://www.instagram.com/" target="_blank">
                  <i class="fa-brands fa-square-instagram"></i>
                </a>
              </span>
            </div>
            <div className="link-sec">
              <p>
                Copyright <span className="carbon-yuwa">&copy; Yuwa</span>{" "}
                {new Date().getFullYear()}
              </p>
            </div>
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3 col-xs-6 col-sm-6 col-6 col-12">
                <div className="footer-sitemap">
                  <h4>Sitemap</h4>
                  <NavLink to="/">
                    <p>Home</p>
                  </NavLink>
                  <a href="#article">
                    {" "}
                    <p>Articles</p>
                  </a>
                  <NavLink to="/askourexpert">
                    <p>Ask our Expert</p>
                  </NavLink>
                  <NavLink to="/contactuspage">
                    <p>Contact</p>
                  </NavLink>
                </div>
              </div>
              <div className="col-md-3 col-xs-6 col-sm-6 col-6 col-12">
                <div className="footer-sitemap">
                  <h4>Link</h4>
                  <ul className="footer-ul">
                    <li>
                      <NavLink to="/resource">
                        <p>Resource</p>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/glossary">
                        <p>Glossary</p>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-xs-6 col-6 col-12 ">
                <div className="footer-sitemap">
                  <h4>Categories</h4>
                  <ul className="footer-ul">
                    {loading ? (
                      <p>loading</p>
                    ) : (
                      category?.map((get, keys) => {
                        return (
                          <li>
                            <NavLink
                              to={`/category/${get?.category_slug}`}
                              state={{
                                title: get?.category_title,
                                description: get?.description,
                              }}
                            >
                              <p>{get.category_title}</p>
                            </NavLink>
                          </li>
                        );
                      })
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-xs-6 col-sm-6 col-6 col-12">
                <div className="footer-sitemap">
                  <h4>Contact us</h4>
                  {/* <div className="all-cont-info">
                <table className="table">
                  <thead>
                    <tr>
                      <td>
                        <span>
                          <i class="fa-solid fa-phone contact-icon"></i>
                          <span className="spn-ele">
                           {
                            loading? <> <Footerloader/></>:<>
                             <a href="tel:+4733378901">{setting?.contact1}</a>
                            </>
                           }
                          </span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <i class="fa-solid fa-phone contact-icon"></i>
                          <span className="spn-ele">
                          {
                            loading ? <><Footerloader/> </> : <>
                              <a href="">
                              {setting?.company_name}
                            </a>
                            </>
                          }
                          </span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="footer-email">
                          <i class="fa-solid fa-envelope contact-icon"></i>
                          &nbsp;
                          <span className="spn-ele ">
                          {
                            loading ? <><Footerloader/> </> : <>
                              <a href="mailto: info@developers.com">
                              {setting?.email1}
                            </a>
                            </>
                          }
                           
                          </span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          <i class="fa-solid fa-file contact-icon"></i>
                          &nbsp;
                          <span className="spn-ele">
                          {
                            loading ? <><Footerloader/> </> : <>
                             <a href="">{setting?.address}</a>
                            </>
                          }
                           
                          </span>
                        </span>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div> */}
                  <ul className="footer-ul">
                    {loading ? (
                      <>
                        <Footerloader />
                      </>
                    ) : (
                      <>
                        <li className="spn-ele">
                          <a href="tel:+4733378901">
                            {" "}
                            <p> {setting?.contact1} </p>
                          </a>
                        </li>
                        <li>
                          <p>{setting?.company_name} </p>
                        </li>
                        <li>
                          <p>{setting?.email1} </p>
                        </li>
                        <li>
                          <p>{setting?.address} </p>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
