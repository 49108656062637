import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Askyourexpertloader = () => {
  return (
 <>
  <div className='col-md-12'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width="100%" height={50} />
                        {/* <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" /> */}
                        
                    </Stack>
                </div>
 </>
  )
}

export default Askyourexpertloader