import React, { useEffect, useState } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Images.css";
import Image from "../../Assets/Images/images2.jpg";
import Imagesloader from "./Imagesloader";

const Images = () => {
  const params = useParams();
  const [getGalleryImage, setGetGalleryImage] = useState([]);
  const [getGalleryVideo, setGetGalleryVideo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileBaseUrl, setFileBaseUrl] = useState("");

  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}get-article-gallery-image?article=${params.slug}&limit=3`,
      method: "GET",
    })
      .then((res) => {
        setFileBaseUrl(res?.data?.data?.file_base_url);
        setGetGalleryImage(res?.data?.data?.images);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
    axios({
      url: `${process.env.REACT_APP_API_URL}get-article-gallery-video?article=${params.slug}&limit=3`,
      method: "GET",
    })
      .then((res) => {
        let response = res?.data?.data;
        setGetGalleryVideo(response.videos);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }, [params.slug]);


  return (
    <>
      <section className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <nav className="tabs-main">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <ul className="tabs-ul">
                    <li className="tabs-li">
                      <button
                        class="nav-link active btn-tabs"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        <span className="span-icon">
                          <i class="fa-solid fa-image"></i>
                        </span>{" "}
                        Images
                      </button>
                    </li>
                    <li className="tabs-li">
                      <button
                        class="nav-link btn-tabs"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        <span className="span-icon">
                          <i class="fa-solid fa-video"></i>
                        </span>
                        Video
                      </button>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
              tabindex="0"
            >
              <SRLWrapper>
                <div className="row">
                  {loading ? (
                   <> <Imagesloader/> </>
                  ) : (
                    getGalleryImage?.data?.map((get, keys) => {
                      return (
                        <div className="col-md-4">
                          <div className="gallery-image" key={keys}>
                            <img src={`${fileBaseUrl}/${get?.file}`} alt="" />
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </SRLWrapper>
            </div>
            <div
              class="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
              tabindex="0"
            >
              < SRLWrapper>
              <div className="row">
                {loading ? (
                  <>
                  <Imagesloader/>
                  </>
                ) : (
                  getGalleryVideo?.data?.map((get, keys) => {
                    return (
                   
                     <div className="col-md-4">
                        <div className="gallery-image">
                          <video width="320" height="240" controls>
                            <source src={`${fileBaseUrl}/${get.file}`} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                  
                    );
                  })
                )}
              </div>
              </SRLWrapper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Images;
