import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Params, useLocation, useParams } from "react-router-dom";
import CardMarkup from "../Cards/InfoCard/CardMarkup";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import "./Category.css";
import Categoryloader from "./Categoryloader";

// <h5>{parse(events?.question?.question || "")}</h5>
function Category() {
  const [category, setCategory] = useState([]);
  const params = useParams();
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchdata, setSearchdata] = useState("");

  const { state } = useLocation();

  const handleChange = (e) => {
    e.preventDefault();
    axios({
      url: `${process.env.REACT_APP_API_URL}get-article-by-category`,
      // url:`${process.env.REACT_APP_API_URL}get-article`,
      method: "GET",
      params: {
        searchKey: searchdata,
      },
    })
      .then((res) => {
        let response = res.data.data.articles.data;
        setCategory(response);
        setImageURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("The error is ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}get-article-by-category`,
      // url: `${process.env.REACT_APP_API_URL}get-article`,
      method: "GET",
      params: {
        category: params.slug,
      },
    })
      .then((res) => {
        console.log(res.data);
        let response = res.data.data.articles.data;
        setCategory(response);
        setImageURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("The error is ", err);
        setLoading(false);
      });
  }, [params]);
  return (
    <>
      <div className="container">
        <h1 className="text-center mt-4 mb-4">{state?.title || "Articles"}</h1>

        <p className="text-center">{state?.description}</p>

        <nav className=" bg-light mt-4 mb-4">
          <div className="text-center">
            <form className="d-flex" role="search" onSubmit={handleChange}>
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchdata}
                onChange={(e) => setSearchdata(e.target.value)}
              />
              {/* <button className="btn btn-outline-success" type="submit" onClick={handleChange}>Search</button> */}
            </form>
          </div>
        </nav>
        <div className="row">
          {loading ? (
            <>
              <Categoryloader />{" "}
            </>
          ) : (
            <>
              {category?.map((event, key) => {
                return (
                  <>
                    <div className="col-md-4 ">
                      {/* <Link to={'/categorydetails'}> */}
                      <div className=" category-section">
                        <CardMarkup
                          cardName={event?.title}
                          cardImage={imageURL + "/" + event?.image}
                          cardDescription={event?.description}
                          btnText="View Details"
                          slug={event?.slug}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Category;
