import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Thirdbannerloader = () => {
  return (
   <>
   <div className='col-md-12 mt-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width="100%" height={200} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="rectangular" width="25%" height={30} />
                        
                    </Stack>
                </div>

   </>
  )
}

export default Thirdbannerloader