import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const Aboutloader = () => {
  return (
<>
<div className="container ">
<div className='row '>
                <div className='col-md-12 m-auto' >
                    <Stack spacing={1}>
                       
                        <Skeleton variant="text" width="25%" height={100} />
                        <Skeleton variant="text" width="100%" />
                     
                        <Skeleton variant="rectangular" width="100%" height="400px" />
                    </Stack>
                </div>
                {/* <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={350} height={300} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                    </Stack>
                </div> */}
                {/* <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={350} height={300} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                    </Stack>
                </div> */}
            </div>
</div>
</>
  )
}

export default Aboutloader
