import axios from 'axios';
import React, {createContext, useEffect , useState} from 'react';

export const ArticleContext = createContext();

export const ArticleProvider = (props) => {
    const [article , setArticle] = useState([]);
  const [loading , setLoading] = useState(false);
  const [imageUrl , setImageUrl] = useState("");

  useEffect(() => {
    setLoading(true);
    axios({
      url:`${process.env.REACT_APP_API_URL}get-article?limit=3`,
      method:"GET",
    }).then((res)=>{
      setLoading(false);
      let response = res?.data?.data?.articles ;
      let imageLink  = res?.data?.data?.image_base_url
      setArticle(response);
      setImageUrl(imageLink);
    }).catch((err) =>{
      console.log(err.message)
      setLoading(false);
    })
  }, []) 

    return(
        <ArticleContext.Provider value={{article,setArticle,loading,setLoading,imageUrl}}>
            {props.children}
        </ArticleContext.Provider>
    )
}