import Aboutus from "../Components/About/Aboutus";
import Pagehead from "../Components/Pageheder/Pagehead";

function Aboutpage() {
  return (
    <>
      <Pagehead heading="About us" />
      <Aboutus />
    </>
  );
}

export default Aboutpage;
