import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Homerouting from "./Homerouting/Homerouting";
import Footer from "./Components/Footer/Footer";
import Joinus from "./Components/Joinus/Joinus"


import TopicList from './Pages/TopicList';

function App() {
  return (
    <>
      <Navbar />
      <Homerouting />
      <Footer />
    </>
  );
}

export default App;
