import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useParams , useNavigate } from "react-router-dom";
import "./Mcq.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import parse from "html-react-parser";
const Mcq = () => {
  const navigate = useNavigate()
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [ArticleMcq, setArticleMcq] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}get-article-mcq?article=${params.slug}`,
    })
      .then((res) => {
        let response = res?.data?.data?.questions?.data?.data;
        setArticleMcq(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }, [params.slug]);

  const [currentSlide, setCurrentSlide] = useState(0);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (index) => setCurrentSlide(index),
        },
      },
      {
        breakpoint: 920,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (index) => setCurrentSlide(index),
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (index) => setCurrentSlide(index),
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (index) => setCurrentSlide(index),
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (index) => setCurrentSlide(index),
        },
      },
    ],
  };

  const answerValue = {
    al: "answer1",
    bi: "answer2",
    ga: "answer3",
    th: "answer4",
  };

  const [nullArray, setNullArray] = useState([]);

  useEffect(() => {
    if (ArticleMcq) {
      if (ArticleMcq.length > 0) {
        let response = [];
        for (let i = 0; i < ArticleMcq.length; i++) {
          response.push(null);
        }
        setNullArray(response);
      }
    }
  }, [ArticleMcq]);

  const handleCheck = (e, key, id, correctAnswer) => {
    let correctRes = [...nullArray];
    if (e.target.value === correctAnswer) {
      correctRes[key] = 1;
      setNullArray(correctRes);
    } else {
      correctRes[key] = 0;
      setNullArray(correctRes);
    }
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const [rightAnswer, setRightAnswer] = useState([]);
  const [incorrectAnswer, setIncorrectAnswer] = useState([]);
  const [accuracy , setAccuracy] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault();
    let tempRight = nullArray.filter(item => item === 1);
    let tempIncorrect = nullArray.filter(item => item === 0);
    let tempAccuracy = (tempRight.length / nullArray.length) * 100;
    setIncorrectAnswer(tempIncorrect);
    setRightAnswer(tempRight);
    setAccuracy(tempAccuracy);
  }

  const BackToArticle = () => {
    navigate(`/categorydetails/${params.slug}`)
  };


  return (
    <>
      <div className="container">
        <h1 className="text-center mt-4 mb-4">Article MCQ's </h1>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="mcq-card">
              <Slider {...settings} ref={sliderRef}>
                {loading ? (
                  <p>loading</p>
                ) : (
                  ArticleMcq.map((get, keys) => {
                    return (
                      <>
                        <div className="mcq-card-qna">
                          <h3>{parse(get?.question)}</h3>
                        </div>
                        <div className="mcq-answer">
                          <ul className="mcq-ul">
                            <li>
                              <div class="check-mcq">
                                <input
                                  type="radio"
                                  className="check-mcq-input"
                                  value="answer1"
                                  id="answer1"
                                  name="answer"
                                  onChange={(e) =>
                                    handleCheck(
                                      e,
                                      keys,
                                      get.id,
                                      answerValue[atob(get.key).split("-")[1]]
                                    )
                                  }
                                />
                                <label className="check-mcq-label">
                                  {get?.answer1}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div class="check-mcq">
                                <input
                                  type="radio"
                                  className="check-mcq-input"
                                  value="answer2"
                                  id="answer2"
                                  name="answer"
                                  onChange={(e) =>
                                    handleCheck(
                                      e,
                                      keys,
                                      get.id,
                                      answerValue[atob(get.key).split("-")[1]]
                                    )
                                  }
                                />
                                <label className="check-mcq-label">
                                  {get?.answer2}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div class="check-mcq">
                                <input
                                  type="radio"
                                  className="check-mcq-input"
                                  value="answer3"
                                  id="answer3"
                                  name="answer"
                                  onChange={(e) =>
                                    handleCheck(
                                      e,
                                      keys,
                                      get.id,
                                      answerValue[atob(get.key).split("-")[1]]
                                    )
                                  }
                                />
                                <label className="check-mcq-label">
                                  {get?.answer3}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div class="check-mcq">
                                <input
                                  type="radio"
                                  className="check-mcq-input"
                                  value="answer4"
                                  id="answer4"
                                  name="answer"
                                  onChange={(e) =>
                                    handleCheck(
                                      e,
                                      keys,
                                      get.id,
                                      answerValue[atob(get.key).split("-")[1]]
                                    )
                                  }
                                />
                                <label className="check-mcq-label">
                                  {get?.answer4}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </>
                    );
                  })
                )}
              </Slider>
              <div className="slider-button">
                <ul>
                  {currentSlide === ArticleMcq.length - 1 ? (
                    <li>
                      <div
                        className="button-slider"
                        onClick={(e)=>handleSubmit(e)}
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <button>Submit</button>
                      </div>
                    </li>
                  ) : (
                    <>
                      <li>
                        {currentSlide === 0 ? (
                          <div className="button-slider" onClick={handlePrev}>
                            <button disabled>Prev</button>
                          </div>
                        ) : (
                          <div className="button-slider" onClick={handlePrev}>
                            <button>Prev</button>
                          </div>
                        )}
                      </li>
                      <li>
                        <div className="button-slider" onClick={handleNext}>
                          <button>Next</button>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
              </div>

              <div
                class="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="staticBackdropLabel">
                        Answer Report
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div className="result">
                        <div className="row">
                          <div className="col-md-10">
                            <p>Total Questions</p>
                            <p>Correct Answer</p>
                            <p>Incorrect Answer</p>
                            <p>Accuracy</p>
                          </div>
                          <div className="col-md-2">
                          <p>{nullArray.length}</p>
                            <p>{rightAnswer.length}</p>
                            <p>{incorrectAnswer.length}</p>
                            <p>{accuracy.toString().substring(0,4)}%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={BackToArticle}
                      >
                        Back To Article
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </>
  );
};
export default Mcq;
