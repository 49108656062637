import React, { useContext } from 'react'
import './Fourthbanner.css'
import video1 from '../../../Assets/Images/video.mp4'
import { AboutContext } from '../../../Context/AboutContext'
import parse  from 'html-react-parser'
import Fourthbannerloader from './Fourthbannerloader'
import ReplaceImg from '../../../Assets/Images/replace.png'
const Fourthbanner = () => {
    const {about , setAbout,loading , setLoading,fileImageUrl , setFileImageUrl} =useContext(AboutContext)
  return (
    <>
    <section className='main-section'>
 <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className='youtube-image'>
                {/* <iframe width="560" height="400" src="https://www.youtube.com/embed/DsZoMbrxO-k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                <img src={ReplaceImg} alt="Image" />
                </div>
            </div>
            <div className="col-md-6 section-description">
              {
                loading ? <><Fourthbannerloader/> </> : <>
                  <div className=''>
                    <h4 className="section-heading">
                        {
                            parse(about?.summary || "") 
                        }
                    </h4>
                    <p>
                        {
                            parse(about?.description?.substring(0,500) || "")
                        }
                    </p>
                </div>
                </>
              }

            </div>
        </div>
    </div>
    </section>
   
    </>
  )
}

export default Fourthbanner