import React, { useEffect } from "react";
// import image3 from "../../Assets/Images/presentation.jpg";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import parse from "html-react-parser";
import axios from "axios";
import { NavLink } from "react-router-dom";
import "./Categorydetails.css";
import Categorydetailsloader from "./Categorydetailsloader";

const Categorydetails = () => {
  const Params = useParams();
  const [categorydetails, setCategorydetails] = useState();
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_URL}get-single-article`,
      method: "Get",
      params: {
        slug: Params.slug,
      },
    })
      .then((res) => {
        let response = res.data.data.article;
        setCategorydetails(response);
        setImageURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("The error is", err);
        setLoading(false);
      });
  }, [Params]);

  return (
    <>
      {loading ? (
        <>
          {" "}
          <Categorydetailsloader />{" "}
        </>
      ) : (
        <>
          <div className="section-background">
            <div className="container-fluid">
              <div className="row first-banner-row">
                <div className="col-md-1 first-banner-social-media"></div>
                <div className="col-md-6">
                  <div>
                    <h1 className="first-banner-heading">
                      {categorydetails?.title}
                    </h1>
                  </div>
                  <div>
                    <NavLink to={`/categorydetails/gallery/${Params.slug}`}>
                      <button className="view-btn">View Gallery</button>
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="first-banner-image">
                    <img src={imageURL + "/" + categorydetails?.image} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div>
              <p className="mt-4 mb-3">
                {parse(categorydetails?.description || "")}
              </p>
            </div>
          </div>

          <section className="main-section">
            <div class="container">
              <div className="sec-head">
                <h4 className="section-heading">Quiz</h4>
              </div>
              {/* <div className="para-sec-text">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, Lorem Ipsum
                  scrambled it to make a type specimen book.
                </p>
              </div> */}
              <div className="join-us-btn all-btn-hover-eff">
                <NavLink to={`/article-mcq/${Params.slug}`}>
                  <button type="button" class="btn">
                    Play Quiz <i class="fa-solid fa-chevron-right"></i>
                  </button>
                </NavLink>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Categorydetails;
