import React from 'react'
import { Stack } from '@mui/system'
import { Skeleton } from '@mui/material'

const Fourthbannerloader = () => {
  return (
    <>
    <div className='col-md-12 '>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width="100%" height={200} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="text" width="100%" height={20} />
                        <Skeleton variant="rectangular" width="25%" height={30} />
                        
                    </Stack>
                </div>
    </>
  )
}

export default Fourthbannerloader