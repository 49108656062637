import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Scrolltotop from "./Components/Scrolltotop/Scrolltotop";
import SimpleReactLightbox from "simple-react-lightbox";
import { CategoryProvider } from "./Context/CategoryContext";
import { ArticleProvider } from "./Context/ArticleContext";
import { AboutProvider } from "./Context/AboutContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SimpleReactLightbox>
    <BrowserRouter>
      <Scrolltotop />
      <CategoryProvider>
        <ArticleProvider>
          <AboutProvider>
            <App />
          </AboutProvider>
        </ArticleProvider>
      </CategoryProvider>
    </BrowserRouter>
  </SimpleReactLightbox>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
