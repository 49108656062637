import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Imagesloader = () => {
  return (
   <>
     <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width="100%" height={200} />
                       
                        
                    </Stack>
                </div>
     <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width="100%" height={200} />
                       
                        
                    </Stack>
                </div>
     <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width="100%" height={200} />
                       
                        
                    </Stack>
                </div>
     
   </>
  )
}

export default Imagesloader