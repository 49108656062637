 import React from 'react'
import { NavLink } from 'react-router-dom';
import CardMarkup from './CardMarkup';
import "./CardStyles.css";

export default function InfoCard(props) {
    return (
        <>
         {/* <h5>{parse(events?.question?.question || "")}</h5> */}

          
          <CardMarkup
                cardName={props.cardName}
                cardDescription={props.cardDescription}
                cardImage={props.cardImage}
                btnText={props.btnText}
                slug={props.slug}
            />
        </>
    )
}
