import axios from 'axios';
import React, {useState , useEffect , createContext} from 'react';


export const CategoryContext = createContext();

export const CategoryProvider = (props) => {

    const [loading , setLoading] = useState(false);
    const [category, setCategory] = useState([]);
    const [imageurl,setImageURL] = useState()

    useEffect(() => {
        setLoading(true);
        axios({
            // url:`${process.env.REACT_APP_API_URL}get-category?limit=999`,
            url:`${process.env.REACT_APP_API_URL}get-category?limit=999`,
            method: 'GET',
        }).then((res) => {
            setLoading(false);
            let response = res?.data?.data?.categories?.data;
            let baseurl = res?.data?.data?.image_base_url;
            setImageURL(baseurl);
            setCategory(response)
            console.log(baseurl);
        }).catch((err) => {
            setLoading(false);
            console.log(err.message);
        })
    }, [])

    return(
        <CategoryContext.Provider value={{category , setCategory , loading ,imageurl,setImageURL}}>
            {props.children}
        </CategoryContext.Provider>
    )
}