import React from 'react'
import Firstbanner from '../Components/Homepage/Firstbanner/Firstbanner'
import Fourthbanner from '../Components/Homepage/Fourthbanner/Fourthbanner'
import Secondbanner from '../Components/Homepage/Secondbanner/Secondbanner'
import Thirdbanner from '../Components/Homepage/Thirdbanner/Thirdbanner'
import Joinus from '../Components/Joinus/Joinus'
import {TopicList} from './TopicList'

const Homepage = () => {
  return (
    <>
    <Firstbanner/>
    <Secondbanner/>
    <Thirdbanner/>
    <Fourthbanner/>  
    <TopicList/>
    <Joinus/>
    </>
  )
}

export default Homepage